import React from "react"
import styled from "styled-components"

import { Type, Color, Wrapper, Media } from "../../utilities"

export const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 3rem;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const SectionContainer = styled.section`
  background: ${props => (props.background ? props.background : Color.white)};
  font-family: ${Type.text};
  color: ${Color.internal};
  padding: 4rem 0;

  h1,
  h2,
  h3,
  h4,
  h4 {
    font-family: ${Type.sub_heading} !important;
    font-style: italic;
    font-weight: normal !important;
  }
`

export const Section = ({ children, maxWidth, wrapperWidth, ...props }) => (
  <SectionContainer {...props}>
    <Wrapper maxWidth={maxWidth} width={wrapperWidth}>
      {children}
    </Wrapper>
  </SectionContainer>
)

import React from "react"
import styled from "styled-components"

import { Media } from "../../utilities"

const HeroContainer = styled.section`
  background-size: cover;
  background-position: center center;
  height: 500px;

  ${({ img }) => img && `background-image: url("${img}");`}

  ${Media.above.extraLarge`
    height: 75vh;
  `}

  ${Media.below.tablet`
    height: 350px;
  `}
`

function Hero(props) {
  const { img } = props

  return <HeroContainer img={img}></HeroContainer>
}

export default Hero

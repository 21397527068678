import React, { Fragment } from "react"
import styled from "styled-components"
import { lighten } from "polished"
import {
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  Color,
  Wrapper,
  Media,
  Type,
  Grid,
  Col,
  Margin,
  AlignCenter,
} from "../../utilities"

const StyledFooter = styled.div`
  background-color: ${Color.dark_grey};
  font-family: ${Type.text};
  font-size: ${Type.basesize};
  padding: 4rem 0rem;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
`

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `};
`

const FooterCol = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  line-height: 0%;
  font-family: ${Type.text};
  color: ${Color.light_grey};
  text-align: right;

  h3 {
    font-size: 1.25rem;
    color: ${lighten(0.2, Color.light_grey)};
    line-height: 150%;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0rem;
    font-weight: bold;
  }

  a {
    color: ${lighten(0.2, Color.light_grey)};
    text-decoration: none;
  }
`

const IconCol = styled.div`
  display: inine-block;
  padding-right: 50%;
  padding-top: 1rem;
  border-left: 1px solid ${Color.light_grey};

  ${Media.below.tablet`
    border-left: none;
  `}
`

const InfoCol = styled.div`
  margin-right: 1.5rem;

  p {
    font-size: 0.7rem;
    color: ${lighten(0.2, Color.light_grey)};
  }
`

const Bottom = styled.div`
  background-color: ${Color.body};
  text-align: center;
  font-family: ${Type.text};
  font-size: 0.9rem;
  font-weight: bold;
  color: ${lighten(0.2, Color.light_grey)};
  padding: 4rem 0rem;

  p {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    text-transform: uppercase;
  }

  h5 {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${Color.light_grey};
  }

  a {
    text-decoration: none;
    color: ${Color.light_grey};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Social = styled.div`
  a {
    color: ${Color.light_grey};
    display: inline-block;
    font-size: 1.5rem;
    padding: 0px 5px;

    &:hover {
      color: ${lighten(0.2, Color.light_grey)};
    }
  }
`

const ButtonGrid = styled(Grid)`
  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const socialArr = [
  {
    key: "facebook",
    icon: faFacebook,
    url: "https://www.facebook.com/knbeautycompany/",
  },
  {
    key: "instagram",
    icon: faInstagram,
    url: "https://www.instagram.com/knbeautycompany/",
  },
]

export const convertUrl = url =>
  url.replace(`http://${process.env.GATSBY_API_URL}`, "")

const getYear = () => {
  const d = new Date()
  return d.getFullYear()
}

const Footer = ({ site = {} }) => (
  <Fragment>
    <StyledFooter>
      <Wrapper>
        <FooterGrid>
          <FooterCol>
            <InfoCol>
              <p>CALL US</p>
              <a href={`tel:${site.phone}`}>
                <h3>{site.phone}</h3>
              </a>
            </InfoCol>
            <IconCol>
              <FontAwesomeIcon icon={faPhoneAlt} />
            </IconCol>
          </FooterCol>
          <FooterCol>
            <InfoCol>
              <p>ADDRESS</p>
              <h3>
                {site.address}
                <br />
                {site.address_two}
              </h3>
            </InfoCol>
            <IconCol>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </IconCol>
          </FooterCol>
          <FooterCol>
            <InfoCol>
              <p>SAY HELLO</p>
              <a href={`mailto:${site.email}`}>
                <h3>{site.email}</h3>
              </a>
            </InfoCol>
            <IconCol>
              <FontAwesomeIcon icon={faEnvelope} />
            </IconCol>
          </FooterCol>
        </FooterGrid>
        <Margin modifiers={["mt5"]} />
        <ButtonGrid>
          <AlignCenter>
            <Col>
              <a
                target="_blank"
                href="https://www.theknot.com/marketplace/redirect-2044912?utm_source=vendor_website&utm_medium=banner&utm_term=3900cd7a-4a46-4d55-8bfe-ca3924149d15&utm_campaign=vendor_badge_assets"
              >
                <img
                  alt="As Seen on The Knot"
                  border="0"
                  src="https://www.xoedge.com/myaccount/2019/website-share/VendorBadge_AsSeenOnWeb.png"
                  width="100"
                />
              </a>
            </Col>
          </AlignCenter>
          <AlignCenter>
            <Col>
              <a
                target="_blank"
                href="https://www.weddingwire.com"
                title="weddingwire.com"
              >
                <img
                  alt="weddingwire.com"
                  src="https://www.weddingwire.com/images/sellos/partner--pp2004787.png"
                  srcset="https://www.weddingwire.com/images/sellos/partner--pp2004787.png 1x, https://www.weddingwire.com/images/sellos/partner--pp2004787.png?largeImg=true 2x"
                  style={{ borderWidth: "0px", height: "70px" }}
                />
              </a>
            </Col>
          </AlignCenter>
        </ButtonGrid>
      </Wrapper>
    </StyledFooter>
    <Bottom>
      <p>
        &copy; {site.title} {getYear()}.
      </p>
      <h5>
        developed by <a href="https://toddprod.com">todd productions inc.</a>
      </h5>
      <Social>
        {socialArr.map(icon => (
          <Fragment>
            {icon.key !== "undefined" && icon.key !== "" && (
              <a href={icon.url} target="_blank">
                <FontAwesomeIcon icon={icon.icon} />
              </a>
            )}
          </Fragment>
        ))}
      </Social>
    </Bottom>
  </Fragment>
)

export default Footer

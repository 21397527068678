import React from "react"
import styled from "styled-components"
import { lighten } from "polished"

import { Col, Color, Type, Media } from "../../utilities"

const TeamMemberStyle = styled.div`
  padding: 1.5rem;
  text-align: center;
  width: 100%;
`

const TeamPic = styled.div`
  height: 250px;
  width: 250px;
  background-color: ${Color.grey};
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;

  ${props =>
    props.picture &&
    `
    background-image: url(${props.picture});
  `}
`

const TeamName = styled.h3`
  font-family: ${Type.header};
  font-size: 1.5rem;
  color: ${Color.text};
  margin-top: 1rem !important;
  margin-bottom: 0.25rem !important;
  ${Media.below.tablet`
    text-align: center;
  `}
`
const TeamTitle = styled.h4`
  font-family: ${Type.text};
  font-size: 1rem;
  color: ${lighten(0.2, Color.text)};
  margin-bottom: 1rem !important;
  ${Media.below.tablet`
    text-align: center;
  `}
`

const TeamBio = styled.div`
  font-family: ${Type.text};
  text-align: left;
  color: ${Color.internal} !important;
  p {
    font-size: 1rem !important;
  }

  ${Media.below.tablet`
    text-align: center;
  `}
`

const TeamGrid = styled.div`
  display: grid;
  width: 100%;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const InfoCol = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;
`

export const TeamMember = ({ name, title, children, picture }) => (
  <TeamMemberStyle>
    <TeamGrid>
      <Col>
        <TeamPic picture={picture} />
      </Col>
      <InfoCol>
        <TeamName>{name}</TeamName>
        {title && <TeamTitle>{title}</TeamTitle>}
        <TeamBio>{children}</TeamBio>
      </InfoCol>
    </TeamGrid>
  </TeamMemberStyle>
)

import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Nav from "../Nav"
import { Color, Wrapper, Type, Media, Col, AlignCenter } from "../../utilities"

const HeaderContainer = styled.div`
  background-size: cover;
  background-color: ${Color.white};
  background-position: center;
  padding-top: 2rem;

  ${Media.below.tablet`
    padding-top: 4rem;
  `}
`
const Logo = styled(Link)`
  display: inline-block;
  img {
    width: 100%;
  }
`

const Address = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: auto;
  font-family: ${Type.sub_heading};
  font-style: italic;
  color: ${Color.text};

  a {
    color: ${Color.text};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    margin-bottom: 0;
  `}
`

const PinkBar = styled.div`
  width: 200px;
  height: 4px;
  background-color: ${Color.pink};
  display: inline-block;

  ${Media.below.tablet`
    display: none;
  `}
`

const Header = ({ nav = [], site = {} }) => {
  const { file } = useStaticQuery(graphql`
    query HeaderQuery {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            src
          }
        }
      }
    }
  `)
  return (
    <HeaderContainer>
      <Wrapper>
        <AlignCenter>
          <Logo to="/">
            <img src={file.childImageSharp.fluid.src} />
          </Logo>
          <Address>
            <Col>{site.address}</Col>
            <Col>
              {site.address_two}
              <AlignCenter>
                <PinkBar />
              </AlignCenter>
            </Col>
            <Col>
              <a href={`tel: ${site.phone}`}>{site.phone}</a>
            </Col>
          </Address>
        </AlignCenter>
      </Wrapper>
      <Nav nav={nav} />
    </HeaderContainer>
  )
}

export default Header

import React from "react"
import styled from "styled-components"

import { TeamMember } from "../TeamMember"
import { Grid, Media } from "../../utilities"

const TeamSection = styled.div`
  padding: 2rem;
`

const TeamGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  justify-items: center;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

export const TeamMembers = ({ staff }) => (
  <TeamSection>
    <TeamGrid>
      {staff.map(item => (
        <TeamMember name={item.name} title={item.title} picture={item.picture}>
          <p>{typeof item.bio === "function" ? item.bio() : item.bio}</p>
        </TeamMember>
      ))}
    </TeamGrid>
  </TeamSection>
)

import React, { Fragment } from "react"
import styled from "styled-components"
import { darken } from "polished"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import { GlobalStyles } from "../../elements"
import { Color, SEO, Media } from "../../utilities"

const Body = styled.div`
  a {
    color: ${darken(0.1, Color.pink)};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const FullImage = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  border-radius: 20px;

  ${Media.below.tablet`
    width: 250px;
    height: 250px;
    margin-top: 0;
  `}

  ${props =>
    props.picture &&
    `
    background-image: url("${props.picture}")
  `}
`

export const fixContentLinks = content => {
  const regex = /^((http|https|ftp):\/\/)/
  if (regex.test(content)) {
    content = content.replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    )
  }

  return content
}

const nav = [
  {
    title: "Welcome",
    url: "/",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Services",
    url: "/services",
  },
  {
    title: "Staff",
    url: "/staff",
  },
  {
    title: "Our Space",
    url: "/our-space",
  },
  {
    title: "Contact",
    url: "/contact",
  },
]

export const Layout = ({ children, title, description }) => {
  const { site } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          phone
          address
          address_two
          email
        }
      }
    }
  `)
  return (
    <Fragment>
      <GlobalStyles />
      <SEO title={title} description={description} />
      <Header nav={nav} site={site.siteMetadata} />
      <Body>
        {typeof children == "function"
          ? children({
            data: site.siteMetadata,
          })
          : children}
      </Body>
      <Footer site={site.siteMetadata} />
    </Fragment>
  )
}

export const Color = {
  pink: "#F7BAC9",
  grey: "#808080",
  dark_grey: "#141414",
  internal: "#828282",
  light_grey: "#4E4E4E",
  body: "#222222",
  text: "#555555",
  yellow: "#fceb26",
  red: "#FF0000",
  white: "#fff",
  black: "#000",
}

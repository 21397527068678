import styled from "styled-components"

export const Col = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  img {
    max-width: 100%;
  }
`

import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { Color, Type, Media, AlignCenter, Col } from "../../utilities"

export const getFileNode = ({ edges, name }) =>
  edges.find(item => item.node.relativePath == name)

const PortfolioContainer = styled.div`
  background-color: ${Color.white};
  background-size: cover;
  padding: 5rem 0;
`

const BannerText = styled.div`
  font-family: ${Type.header};
  font-weight: 800;
  color: ${Color.section};
  text-align: center;
  line-height: 50%;

  span {
    color: ${Color.red};
  }

  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  h4 {
    font-family: ${Type.sub_heading};
    color: ${Color.light_grey};
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
`

const RedBar = styled.div`
  display: inline-block;
  height: 1px;
  width: 50px;
  background: ${Color.red};
`

const GirlGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-transform: uppercase;
  text-align: center;

  h3 {
    font-family: ${Type.header};
    color: ${Color.section};
    font-weight: bold;
    font-size: 1rem !important;
    margin-bottom: 0;
  }

  h4 {
    font-family: ${Type.sub_heading};
    color: ${Color.light_grey};
    font-style: italic;
    font-size: 1rem !important;
    margin-bottom: 0;
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const GirlPicture = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;

  ${Media.below.tablet`
    width: 250px;
    height: 250px;
  `}

  ${props =>
    props.picture &&
    `
    background-image: url("${props.picture}")
    `}
`

const girls = ({ edges }) => [
  {
    image: getFileNode({ edges, name: "coloring-new.jpg" }).node.childImageSharp
      .fluid.src,
    title: "hair",
    job: "coloring",
  },
  {
    image: getFileNode({ edges, name: "styling-new.jpg" }).node.childImageSharp
      .fluid.src,
    title: "hair",
    job: "styling",
  },
  {
    image: getFileNode({ edges, name: "highlights-new.jpg" }).node
      .childImageSharp.fluid.src,
    title: "hair",
    job: "highlights",
  },
  {
    image: getFileNode({ edges, name: "eyebrow-wax.jpg" }).node.childImageSharp
      .fluid.src,
    title: "eyebrow",
    job: "waxing",
  },
  {
    image: getFileNode({ edges, name: "mens-hair.jpg" }).node.childImageSharp
      .fluid.src,
    title: "Hair",
    job: "Men's Cuts",
  },
  {
    image: getFileNode({ edges, name: "lash-lift.jpg" }).node.childImageSharp
      .fluid.src,
    title: "eyelash",
    job: "lifts",
  },
  {
    image: getFileNode({ edges, name: "lash-lift-tint.jpg" }).node
      .childImageSharp.fluid.src,
    title: "eyelash",
    job: "tinting",
  },
  {
    image: getFileNode({ edges, name: "microblading.jpg" }).node.childImageSharp
      .fluid.src,
    title: "eyebrows",
    job: "microblading",
  },
  {
    image: getFileNode({ edges, name: "makeup.jpg" }).node.childImageSharp.fluid
      .src,
    title: "wedding",
    job: "makeup",
  },
]

export const PortfolioSection = ({ title, tag }) => {
  const { allFile } = useStaticQuery(graphql`
    query PortfolioQuery {
      allFile(
        filter: {
          relativePath: {
            in: [
              "coloring-new.jpg"
              "styling-new.jpg"
              "highlights-new.jpg"
              "eyebrow-wax.jpg"
              "mens-hair.jpg"
              "lash-lift.jpg"
              "lash-lift-tint.jpg"
              "microblading.jpg"
              "makeup.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <PortfolioContainer>
      <BannerText>
        <h3>
          {title}
          <span>.</span>
        </h3>
        {tag && <h4>{tag}</h4>}
      </BannerText>
      <AlignCenter>
        <RedBar />
      </AlignCenter>
      <GirlGrid>
        {girls({ edges: allFile.edges }).map(item => (
          <Col>
            <GirlPicture picture={item.image} />
            <h3>{item.title}</h3>
            <h4>{item.job}</h4>
          </Col>
        ))}
      </GirlGrid>
    </PortfolioContainer>
  )
}

import React from "react"
import styled from "styled-components"

import { Color, Type } from "../../utilities"

const SigStyles = styled.div`
  font-family: ${Type.sub_heading};
  font-style: italic;
  span {
    color: ${Color.pink};
  }
`

export const Signature = () => (
  <SigStyles>
    X<span>O</span>X<span>O</span>, KN Beauty Co.
  </SigStyles>
)

import React, { useState } from "react"
import { darken } from "polished"
import { Link } from "gatsby"
import styled from "styled-components"

import { Color, Wrapper, Type, Media } from "../../utilities"

const NavContainer = styled.div`
  font-family: ${Type.text};
  background-size: cover;
  background-color: ${Color.white};
  background-position: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${Media.below.tablet`
    margin-top: 0;
    margin-bottom: 2rem;
  `}
`

const NavFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;

  ${Media.below.tablet`
    margin: 0;
    display: ${props => (props.on ? `flex` : `none`)};
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
  `}
`

const ExitButton = styled.span`
  position: absolute;
  z-index: 9001;
  right: 0px;
  top: 0px;
  font-size: 2rem;
  height: 40px;
  width: 40px;
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 5px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  color: ${darken(0.4, Color.dark_grey)};
  border-radius: 100%;

  ${Media.below.tablet`
    display: flex;
  `}
`

const Burg = styled.div`
  display: none;
  z-index: 101;
  position: absolute;
  width: 25px;
  height: 4px;
  right: 20px;
  top: 40px;
  cursor: pointer;
  background: ${Color.pink};

  &::before,
  &::after {
    left: 0;
    content: "";
    margin-top: -10px;
    position: absolute;
    width: 100%;
    height: 4px;
    background: ${Color.pink};
  }

  &::after {
    margin-top: 10px;
    background: ${Color.pink};
  }

  ${Media.below.tablet`
    display: block;
  `}
`

const NavLink = styled(Link)`
  text-decoration: none;
  font-family: ${Type.text};
  font-size: 0.9rem;
  color: ${Color.text};
  padding: 0.8rem 1.5rem;
  text-transform: uppercase;
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  border-radius: 50px;

  &:hover {
    transform: scale(1.1);
    border: 1px solid ${Color.dark_grey};
  }

  ${Media.below.tablet`
    font-family: ${Type.header};
    color: ${Color.text};
  `}
`

const Nav = ({ nav = [] }) => {
  const [on, setOn] = useState(false)

  return (
    <NavContainer>
      <Wrapper>
        <Burg onClick={() => setOn(!on)} />
        <NavFlex on={on}>
          <ExitButton onClick={() => setOn(!on)}>x</ExitButton>
          {nav.map((item, ix) => (
            <NavLink
              key={ix}
              activeStyle={{
                border: `1px solid ${Color.pink}`,
                borderRadius: "50px",
                backgroundColor: Color.pink,
              }}
              to={item.url}
            >
              {item.title}
            </NavLink>
          ))}
        </NavFlex>
      </Wrapper>
    </NavContainer>
  )
}

export default Nav
